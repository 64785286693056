import './style.scss';

function Banner(){
  return(

    <div className="bannerTxt">
      <div className="containerAccroche"> Développez votre visibilité !</div>
      <div className="demandedevisTxt">Demandez un devis gratuit</div>
     
    </div>
 
  );

}
export default Banner;