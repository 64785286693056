import Why from '../../components/Why';
import Prestations from '../../components/Prestations';
import Contact from '../../components/Contact';
import Banner from '../../components/Banner';
function Accueil(){

  return <div>
    <Banner />
    <Why/>
    <Prestations/>
    <Contact/>

  </div>;
}
export default Accueil;